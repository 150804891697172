import request from '@/plugins/axios'

//添加新标签
export function addTagApi(data) {
  return request({
    // url: '/tagged/tags',
    url: '/tagged/tags:get_or_create',
    method: 'post',
    data
  })
}
// ==========================群组
//新增群组标签
export function addGroupApi(data) {
  return request({
    url: `/tagged/group_tag_relations:get_or_create`,
    method: 'post',
    data
  })
}
//删除群组标签
export function delGroupApi(group_id, tag_id) {
  return request({
    url: `/whatsapp/groups/${group_id}/tags/${tag_id}`,
    method: 'delete'
  })
}
// ==========================用户
//新增用户标签
export function addUserApi(data) {
  return request({
    // url: `/tagged/user_tag_relations`,
    url: `/tagged/user_tag_relations:get_or_create`,
    method: 'post',
    data
  })
}
//删除用户标签
export function delUserApi(user_id, tag_id) {
  return request({
    url: `/whatsapp/users/${user_id}/tags/${tag_id}`,
    method: 'delete'
  })
}
// ==========================消息
//新增消息标签
export function addMsgApi(data) {
  return request({
    // url: `/tagged/message_tag_relations`,
    url: `/tagged/message_tag_relations:get_or_create`,
    method: 'post',
    data
  })
}
//删除消息标签
export function delMsgApi(message_id, tag_id) {
  return request({
    url: `/whatsapp/messages/${message_id}/tags/${tag_id}`,
    method: 'delete'
  })
}
