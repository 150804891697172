import request from '@/plugins/axios'

export function login(data) {
  return request({
    url: '/authenticate/login',
    method: 'post',
    data
  })
}
export function authLogin(data) {
  return request({
    url: '/authenticate/auth_login',
    method: 'post',
    data
  })
}

export function logOut() {
  return request({
    url: '/authenticate/logout',
    method: 'post'
  })
}

export function userList(data) {
  return request({
    url: '/authenticate/users',
    method: 'get',
    params: data
  })
}

export function updateUser(userId, data) {
  return request({
    url: `/authenticate/users/${userId}`,
    method: 'patch',
    data
  })
}

export function passwordApi(id, data) {
  return request({
    url: `/authenticate/users/${id}:change_password`,
    method: 'post',
    data
  })
}
