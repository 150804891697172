import Layout from '@/layout'
function addRouteFun(routerObj) {
  let obj = {
    path: '/administrators',
    component: Layout,
    name: 'administrators',
    children: [
      {
        path: 'page',
        name: 'administratorsPage',
        // component: () => import('@/views/administrators/Administrators'),
        meta: { title: '管理员', icon: 'el-icon-s-grid' }
      }
    ]
  }
  let typeData = localStorage.getItem('roles')

  if (typeData === 'true') {
    // console.log('添加路由')
    routerObj.addRoute('/', obj)
  }
}
export default addRouteFun
