<template>
  <div class="tag-input-box">
    <el-button v-if="type" :size="size" @click="changeFun">+新标签</el-button>
    <el-input
      id="tag-input"
      v-else
      :size="size"
      :style="{ width: width + 'px' }"
      v-model="input"
      placeholder="标签名"
      @blur="inputBlurFun"
    ></el-input>
  </div>
</template>
<script>
import { addTagApi } from '@/api/tag'
export default {
  name: 'tag_page',
  props: {
    size: {
      type: String,
      default: 'small' //	medium / small / mini
    },
    width: {
      type: [String, Number],
      default: 80
    }
  },
  data() {
    return {
      type: true,
      input: ''
    }
  },
  created() {},
  methods: {
    closeFun() {},
    changeFun() {
      this.input = ''
      this.type = !this.type
      this.$nextTick(() => {
        if (!this.type) {
          let dom1 = document.getElementById('tag-input')
          dom1.focus()
        }
      })
    },
    inputBlurFun() {
      if (this.input.trim() == '' || !this.input.trim()) {
        this.type = !this.type
      } else {
        addTagApi({
          tag: this.input
        })
          .then(res => {
            // this.$message.success('新标签添加成功！')
            this.$emit('updateTagFun', res)
            this.type = !this.type
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '标签添加失败！'
            })
          })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tag-input-box {
  display: inline-block;
}
</style>
