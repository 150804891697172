const state = {
  width: 0,
  height: 0,
  showExamples: true,
  noRead: {
    tipChannelMap: {},
    userKeyMap: {},
    userRemarkMap: {}
  }
}

const mutations = {
  SETWIDTH(state, num) {
    state.width = num
  },
  SETHEIGHT(state, num) {
    state.height = num
    // console.log(state.height);
    // console.log('高度：' + state.height);
  },
  SET_SHOW(state, stateData) {
    state.showExamples = stateData
  },
  SET_NOREAD(state, obj) {
    state.noRead = Object.assign({}, obj)
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
