const getDefaultState = () => {
  return {
    // token: getToken(),
    token: '',
    name: '',
    avatar: '',
    userInfo: {}
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: state => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_INFO(state) {
    const obj = JSON.parse(localStorage.getItem('userInfo'))
    state.userInfo = obj
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
