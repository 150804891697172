<template>
  <div class="search-right">
    <div class="search-right-box">
      <el-form :model="queryForm" label-position="top">
        <el-form-item label="关键词" prop="">
          <el-input
            type="textarea"
            v-model="queryForm.search"
            placeholder="关键词..."
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <el-radio v-model="queryForm.radio" label="3">
              短句模式
              <img title="推荐" src="@/assets/imgs/i29.png" alt="" />
            </el-radio>
          </template>
          <div class="memo-text">
            短句模式会将您输入的内容拆分，充分考虑拆分后的内容及拆分后的顺序关系，是较为均衡的模式，最适合日常快速检索使用。
          </div>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <el-radio v-model="queryForm.radio" label="2">分词模式</el-radio>
          </template>
          <div class="memo-text">
            分词模式会将您输入的内容拆分，并优先推荐匹配占比高的内容，但部分匹配度不高的内容可能会影响正常浏览。
          </div>
        </el-form-item>
        <!-- <el-form-item>
          <template slot="label">
            <el-radio v-model="queryForm.radio" label="1">
              匹配模式
              <img
                style="width: 42px;"
                title="不推荐"
                src="@/assets/imgs/i28.png"
                alt=""
              />
            </el-radio>
          </template>
          <div class="memo-text">
            匹配模式会将您输入的内容进行精确匹配，不会拓展返回相似内容，不会高亮显示关键词，且查询速度极慢，容易出现查询超时现象，不建议日常使用。
          </div>
        </el-form-item> -->
      </el-form>
    </div>
    <div class="search-right-btn">
      <el-button style="width: 100%" type="primary" @click="submitFun">
        检索
      </el-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryForm: {
        radio: '3'
      }
    }
  },
  methods: {
    submitFun() {
      this.$emit('submitFun', this.queryForm)
    }
  }
}
</script>
<style scoped lang="scss">
.search-right {
  width: 100%;
  height: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  position: relative;

  .search-right-box {
    padding: 10px;
    box-sizing: border-box;
    height: calc(100% - 70px);
    overflow: auto;
    ::v-deep .el-form-item__label {
      margin-bottom: 0 !important;
      padding: 0;
    }

    .memo-text {
      padding: 10px;
      box-sizing: border-box;
      background: #e5e5e5;
      line-height: 20px;
      color: #7c7b7a;
      font-size: 12px;
    }
  }
  .search-right-btn {
    padding: 0 10px;
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    bottom: 10px;
    box-sizing: border-box;
  }
  img {
    vertical-align: middle;
    margin-left: 5px;
  }
}
</style>
