import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import addRouteFun from './administrators'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    name: 'indexParent',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/index/Index'),
        meta: { title: '首页', icon: 'el-icon-house' }
      }
    ]
  },
  {
    name: 'Search',
    path: '/search',
    component: Layout,
    redirect: '/search/message',
    meta: {
      title: '数据检索',
      icon: 'el-icon-search'
    },
    children: [
      {
        name: 'Message',
        path: 'message',
        component: () => import('@/views/search/Message.vue'),
        meta: {
          title: '聊天记录',
          icon: 'el-icon-chat-line-square'
        }
      },
      {
        name: 'User',
        path: 'user',
        component: () => import('@/views/search/User.vue'),
        meta: {
          title: '用户信息',
          icon: 'el-icon-user-solid'
        }
      },
      {
        name: 'Group',
        path: 'group',
        component: () => import('@/views/search/Group.vue'),
        meta: {
          title: '群组数据',
          icon: 'el-icon-postcard'
        }
      },
      {
        name: 'GroupInviteLink',
        path: 'invite_link',
        component: () => import('@/views/search/GroupInviteLink.vue'),
        meta: {
          title: '群组链接',
          icon: 'el-icon-connection'
        }
      }
    ]
  },
  {
    name: 'Clue',
    path: '/clue',
    component: Layout,
    redirect: '/clue/plan',
    meta: {
      title: '线索推送',
      icon: 'el-icon-news'
    },
    children: [
      {
        name: 'Plan',
        path: 'plan',
        component: () => import('@/views/clue/Plan.vue'),
        meta: {
          title: '方案管理',
          icon: 'el-icon-edit-outline'
        }
      },
      {
        name: 'Result',
        path: 'result',
        component: () => import('@/views/clue/Result.vue'),
        meta: {
          title: '线索数据',
          icon: 'el-icon-data-analysis'
        }
      }
    ]
  },
  {
    name: 'Tagged',
    path: '/tagged',
    component: Layout,
    redirect: '/tagged/messageTag',
    meta: {
      title: '标签管理',
      icon: 'el-icon-document'
    },
    children: [
      {
        name: 'MessageTag',
        path: 'messageTag',
        component: () => import('@/views/tagged/MessageTag.vue'),
        meta: {
          title: '消息标签',
          icon: 'el-icon-s-comment'
        }
      },
      {
        name: 'GroupTag',
        path: 'groupTag',
        component: () => import('@/views/tagged/GroupTag.vue'),
        meta: {
          title: '群组标签',
          icon: 'el-icon-s-flag'
        }
      },
      {
        name: 'UserTag',
        path: 'userTag',
        component: () => import('@/views/tagged/UserTag.vue'),
        meta: {
          title: '用户标签',
          icon: 'el-icon-user'
        }
      },
      {
        name: 'Tag',
        path: 'tag',
        component: () => import('@/views/tagged/Tag.vue'),
        meta: {
          title: '标签管理',
          icon: 'el-icon-document-remove'
        }
      }
      // {
      //   name: 'Result',
      //   path: 'result',
      //   component: () => import('@/views/clue/Result.vue'),
      //   meta: {
      //     title: '线索数据',
      //     icon: 'el-icon-upload2'
      //   }
      // }
    ]
  },
  {
    name: 'Gather',
    path: '/gather',
    component: Layout,
    redirect: '/gather/group',
    meta: {
      title: '自定义采集',
      icon: 'el-icon-set-up'
    },
    children: [
      {
        name: 'Group',
        path: 'group',
        component: () => import('@/views/gather/Group.vue'),
        meta: {
          title: '提交群组',
          icon: 'el-icon-finished'
        }
      }
    ]
  },
  {
    name: 'Detect',
    path: '/detect',
    component: Layout,
    redirect: '/detect/account',
    meta: {
      title: '落查模块',
      icon: 'el-icon-s-marketing'
    },
    children: [
      {
        name: 'Account',
        path: 'account',
        component: () => import('@/views/detect/Account.vue'),
        meta: {
          title: '账号落查',
          icon: 'el-icon-s-custom'
        }
      },
      {
        name: 'Phone',
        path: 'phone',
        component: () => import('@/views/detect/Phone.vue'),
        meta: {
          title: '手机反查',
          icon: 'el-icon-mobile-phone'
        }
      },
      {
        name: 'AccountHitRate',
        path: 'hit_rate',
        component: () => import('@/views/detect/AccountHitRate.vue'),
        meta: {
          title: '命中率查询',
          icon: 'el-icon-aim'
        }
      },
      {
        name: 'Points',
        path: 'points',
        component: () => import('@/views/detect/Points.vue'),
        meta: {
          title: '积分使用记录',
          icon: 'el-icon-coin'
        }
      }
      // {
      //   name: 'Result',
      //   path: 'result',
      //   component: () => import('@/views/clue/Result.vue'),
      //   meta: {
      //     title: '线索数据',
      //     icon: 'el-icon-upload2'
      //   }
      // }
    ]
  },
  {
    name: 'log',
    path: '/log',
    component: Layout,
    redirect: '/log/login',
    meta: {
      title: '日志',
      icon: 'el-icon-info'
    },
    children: [
      {
        name: 'logLogin',
        path: 'login',
        component: () => import('@/views/log/LoginLog.vue'),
        meta: {
          title: '登录日志',
          icon: 'el-icon-s-flag'
        }
      },
      {
        name: 'logChange',
        path: 'programme',
        component: () => import('@/views/log/ProgrammeLog.vue'),
        meta: {
          title: '方案记录',
          icon: 'el-icon-notebook-1'
        }
      },
      {
        name: 'logChange',
        path: 'label',
        component: () => import('@/views/log/LabelLog.vue'),
        meta: {
          title: '标签记录',
          icon: 'el-icon-tickets'
        }
      },
      {
        name: 'logRequest',
        path: 'request',
        component: () => import('@/views/log/RequestLog.vue'),
        meta: {
          title: '查询日志',
          icon: 'el-icon-reading'
        }
      }
    ]
  },
  {
    name: 'Admin',
    path: '/admin',
    component: Layout,
    redirect: '/admin/users',
    meta: {
      title: '管理员',
      icon: 'el-icon-s-tools'
    },
    children: [
      {
        name: 'Users',
        path: 'users',
        component: () => import('@/views/admin/Users.vue'),
        meta: {
          title: '用户管理',
          icon: 'el-icon-s-custom'
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login.vue')
  },
  {
    path: '/authLogin',
    name: 'AuthLogin',
    component: () => import('@/views/login/AuthLogin.vue')
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/errorPage/401.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/errorPage/404.vue')
  },
  // 用户详情
  {
    path: '/user',
    component: Layout,
    redirect: '/user/detail',
    name: 'userPage',
    hidden: true,
    children: [
      {
        meta: {
          keepAlive: true
        },
        path: 'detail/:userId',
        name: 'userDetail',
        component: () => import('@/views/detail/UserDetail.vue')
      }
    ]
  },
  // 群组详情
  {
    path: '/group',
    component: Layout,
    redirect: '/group/detail',
    name: 'groupPage',
    hidden: true,
    children: [
      {
        // meta: {
        //   keepAlive: true
        // },
        path: 'detail/:groupId',
        name: 'GroupDetail',
        component: () => import('@/views/detail/GroupDetail.vue')
      }
    ]
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
addRouteFun(router)

export default router
