const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  keepAliveArr: state => {
    const arr = ['GroupDetail', 'UserDetailPage']
    const tab = state.settings.tabs.map(item => {
      return item.componentsName
    })
    if (tab.length > 0) {
      tab.forEach(element => {
        if (arr.indexOf(element) == -1 && element !== undefined) {
          arr.push(element)
        }
      })
    }
    return arr
  }
}
export default getters
