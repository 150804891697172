import pagination from './pagination/Pagination.vue'
import noData from './pagination/NoData.vue'
import totalAll from './pagination/TotalAll.vue'
import Nomore from './pagination/Nomore.vue'
import searchRight from './search/SearchRight.vue'
import tagComponents from './TagComponents.vue'
const components = {
  install: function(Vue) {
    Vue.component('pagination', pagination)
    Vue.component('noData', noData)
    Vue.component('totalAll', totalAll)
    Vue.component('noMorePage', Nomore)
    Vue.component('searchRight', searchRight)
    Vue.component('tagComponents', tagComponents)
  }
}
export default components
