<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="keepAliveRoutes">
        <router-view :key="key" />
      </keep-alive>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path
    },
    keepAliveRoutes() {
      // return this.$store.state.settings.keepAliveRoutes
      // return this.$store.getters.keepAliveArr
      // return this.$store.state.settings.tabs.map(item => {
      //   return item.path
      // })
      return [
        'searchMessage', //数据检索--聊天记录
        'searchUser', //数据检索--用户信息
        'searchGroup', //数据检索--群组数据
        'searchGroupLink', //数据检索--群组链接
        'UserDetailPage', //用户详情
        'GroupDetail', //群组详情
        'cluePlan', //方案管理
        'clueResult', //线索数据

        'MessageTag', //消息标签
        'GroupTag', //群组标签
        'UserTag', //用户标签
        'tagManage', //标签管理

        'gatherGroup', //提交群组

        'detectAccount', //账号落查
        'detectPhone', //手机反查
        'AccountHitRate', //命中率查询
        'detectPoints', //积分

        'userManage'//用户管理
      ]
    }
  },
  data() {
    return {
      timer: null
    }
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {}
}
</script>

<style scoped>
.app-main {
  /*50 = navbar  */
  /* min-height: calc(100vh - 50px); */
  min-height: calc(100vh - 80px);
  width: 100%;
  position: relative;
  overflow: hidden;
  /* margin-top: 5px; */
}
.fixed-header + .app-main {
  padding-top: 50px;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
